function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
    text = 'https://' + text;
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
    });
}


async function createSidUrl(link) {
    const default_link = 'SIDurl.com';
    const Http = new XMLHttpRequest();
    const url = 'https://xd1pv8phy4.execute-api.us-east-2.amazonaws.com/Prod/';
    Http.open("POST", url, true);
    Http.setRequestHeader('Content-Type', 'application/json');
    Http.send(JSON.stringify({
        url: link
    }));

    let target_label = document.getElementById('shortened_url');
    Http.onreadystatechange = (e) => {
        if (Http.status == 200 && Http.responseText.length != 0) {
            console.log('result')
            let endpoint = JSON.parse(Http.responseText)['short_url'];
            let url = 'l.sidurl.com/' + endpoint;
            target_label.innerHTML = url;
            target_label.href = 'https://' + url;
        } else if (Http.status == 400 || Http.status == 500) {
            target_label.innerHTML = default_link;
        } else {

        }
    }
}

export {
    copyTextToClipboard,
    createSidUrl
}

